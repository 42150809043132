.admin-tab-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 20px;
  }
  
  .form-container {
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
  }
  
  .form-add-user, .form-edit-user {
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    border: 1px solid #ccc;
  }
  
  .form-row {
    display: flex;
    gap: 20px;
  }
  
  input[type="text"], input[type="email"], input[type="password"], select {
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  
  input[type="file"] {
    margin-top: 10px;
  }
  
  button {
    padding: 10px 20px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  button.btn-primary {
    background-color: rgb(33, 119, 223);
    color: rgb(0, 0, 0);
    border: none;
    
    
  }
  
  button.btn-primary:hover {
    background-color: pink;
    color: white;
  }
  
  button.btn-danger {
    background-color: red;
    color: white;
    border: none;
  }
  
  button.btn-danger:hover {
    background-color: darkred;
    color: white;
  }
  
  button.btn-secondary {
    background-color: #ccc;
    color: black;
    border: none;
  }
  
  button.btn-secondary:hover {
    background-color: #888;
    color: white;
  }
  
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
  }
  
  .user-list-container {
    flex: 0 0 40%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-right: 15px;
}
  
  .user-details-container {
    flex: 0 0 60%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    text-align: center;
  }
  
  .user-list {
    list-style-type: none;
    padding: 0;
  }
  
  .user-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .user-item:hover {
    background-color: #f0f0f0;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .avatar-large {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 20px;
    margin: 0 auto
  }
  .btn-group button {
    display: flex;
    align-items: center; /* Aligner les icônes avec le texte */
    justify-content: center;
    gap: 10px; /* Espacement entre l'icône et le texte */
    padding: 10px 20px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .bouton1{

    background-color: pink;
  }

  .bouton1:hover {

    background-color: skyblue;
  }